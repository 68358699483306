<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <grafico-pontos-entrada-municipio
          :placa="placa"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <grafico-pontos-saida-municipio
          :placa="placa"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {

    components: {
      GraficoPontosEntradaMunicipio: () => import('@/components/chart/perfil-veiculo/GraficoPontosEntradaMunicipio'),
      GraficoPontosSaidaMunicipio: () => import('@/components/chart/perfil-veiculo/GraficoPontosSaidaMunicipio'),
    },

    props: {
      placa: {
        type: String,
        default: '',
      },
    },

    data () {
      return {

      }
    },

    methods: {

    },

  }
</script>
